<template>
  <tr>
    <td>
      <strong>{{ utm_tag.task.created_date }}</strong>
    </td>
    <td>
      <h5 class="mb-0">
        <strong class="font-medium">#{{ utm_tag.task.id }}</strong>
        <strong class="margin-l-5 font-medium" style="position: absolute;">
          {{ utm_tag.task.type.name }}
        </strong>

        <span class="label label-default text-white font-medium pull-right"
              :style="'padding: 2px 6px; font-size: 14px; background:' + utm_tag.task.type_status.custom_color.replace('0.25', '1')">
            {{ utm_tag.task.type_status.name }}
          </span>
      </h5>
    </td>
    <td>
      {{ utm_tag.task.client.name }}
      <br/>
      <i class="fa fa-phone-square text-blue" />
      <a :href="'tel:+' + utm_tag.task.client.phone">
        {{ utm_tag.task.client.phone }}
      </a>
    </td>
    <td>
      {{ utm_tag.utm_source }}
    </td>
    <td>
      {{ utm_tag.utm_medium }}
    </td>
    <td>
      {{ utm_tag.utm_campaign }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "utm-tag-item",

  components: {

  },

  props: {
    utm_tag: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  methods: {

  },
};
</script>

<style scoped>

</style>
